import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '@commons/services/api';

import { updateAuth, setIsAdmin } from './actions';

export function* signIn({ payload, resolve, reject }) {
  try {
    const { username, password } = payload;

    var data = new FormData();

    data.set('username', username);
    data.set('password', password);

    const response = yield call(api.post, '/auth/login', data);

    const { access_token } = response.data;

    yield put(updateAuth(access_token));

    resolve(response.data);
  } catch (err) {
    reject(err);
  }
}

export function* resetPassword({ payload, resolve, reject }) {
  try {
    const { email } = payload;

    const response = yield call(api.post, '/auth/reset-password', { email });

    resolve(response.data);
  } catch (err) {
    reject(err);
  }
}

export function* getUserData({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/user');

    const isAdmin = response?.data?.admin === 'Y';

    yield put(setIsAdmin(isAdmin));

    resolve();
  } catch (err) {
    reject(err);
  }
}

export default all([
  takeLatest('@auth/SIGN_IN', signIn),
  takeLatest('@auth/RESET_PASSWORD', resetPassword),
  takeLatest('@auth/GET_USER_DATA', getUserData),
]);
