import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { map } from 'lodash';

import apllyDashboardTemplate from '@commons/containers/Dashboard';
import history from '@commons/services/history';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import { getLeiloes } from '@commons/store/modules/leiloes/actions';

import LeilaoCard from './LeilaoCard';
import { Container } from './styles';

export const Leiloes = () => {
  const dispatch = useDispatch();

  const [leiloes, setLeiloes] = useState([]);

  useEffect(() => {
    dispatch(setLoading(true));

    const getLeiloesPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(getLeiloes(resolve, reject));
      });
    };

    getLeiloesPromise()
      .then(response => {
        setLeiloes(response);
      })
      .finally(() => dispatch(setLoading(false)));
    //eslint-disable-next-line
  }, []);

  const onCardClick = id => {
    history.push(`/leiloes/${id}`);
  };

  return (
    <Container>
      {leiloes && (
        <>
          {map(leiloes, leilao => {
            return (
              <LeilaoCard
                key={leilao.id}
                leilao={leilao}
                onClick={() => onCardClick(leilao.id)}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};

export default apllyDashboardTemplate(Leiloes);
