import React, { useMemo, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import { TableRow, Table, TableBody } from '@commons/components/Table';
import colors from '@commons/styles/colors';
import { currency } from '@commons/utils/formatters';

import { TableContainer, CardTitle, Container } from './styles';
import { columns } from './utils';

export const HistoricoLances = ({ lances }) => {
  const tableRef = useRef(null);

  const tableData = useMemo(() => {
    return map(lances, data => {
      return {
        ...data,
        valor: {
          value: currency(data.valor),
          style: { color: colors.green, fontWeight: 'bold' },
        },
      };
    });
  }, [lances]);

  useEffect(() => {
    if (!!tableRef.current) {
      // Scroll to bottom
      tableRef.current.scrollTop = 0;
    }
  }, [tableRef, lances]);

  return (
    <Container>
      <CardTitle>Últimos 5 lances:</CardTitle>
      <TableContainer ref={tableRef}>
        <Table>
          <TableBody>
            {map(tableData, item => (
              <TableRow key={item.id} data={item} columns={columns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

HistoricoLances.propTypes = {
  lances: PropTypes.array.isRequired,
};

export default HistoricoLances;
