import React from 'react';
import { Rnd } from 'react-rnd';

import colors from '@commons/styles/colors';

import { DragButton, DragHandleIcon } from './styles';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `solid 2px ${colors.gray}`,
  zIndex: 9999,
  borderRadius: '4px',
  overflow: 'hidden',
  background: colors.white,
};

export const Video = ({ html }) => {
  return (
    <>
      {!!html && (
        <Rnd
          style={style}
          default={{
            x: window.innerWidth - 500,
            y: window.innerHeight - 350,
          }}
          enableResizing={false}
        >
          <DragButton>
            <DragHandleIcon />
          </DragButton>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </Rnd>
      )}
    </>
  );
};

export default Video;
