import styled from 'styled-components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OriginalArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OriginalInfoIcon from '@material-ui/icons/Info';
import OriginalPhoneIcon from '@material-ui/icons/Phone';

import { borderAlertColor } from './utils';

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

export const CardTitle = styled.h4`
  font-size: 1.3rem;
  font-weight: normal;
`;

export const NextPageLink = styled.a`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  &::first-child {
    margin-right: 10px;
  }
`;

export const ArrowForwardIcon = styled(OriginalArrowForwardIcon)`
  height: 0.9rem;
  font-weight: bold;
`;

export const RightSide = styled.div`
  flex-basis: 46%;
  display: flex;
  flex-direction: column;

  li {
    font-size: 0.9rem;

    &:not(:first-child) {
      margin: 10px 0;
    }
  }

  @media (max-width: 1300px) {
    flex-basis: 40%;
  }

  ${props => props.theme.breakPoints.notebook} {
    flex-basis: 100%;
  }
`;

export const LeftSide = styled.div`
  flex-basis: 50%;

  ${props => props.theme.breakPoints.notebook} {
    margin-bottom: 50px;
    flex-basis: 100%;
  }
`;

export const Bold = styled.b`
  font-size: 1.3rem;
  color: ${props => (props.color ? props.color : props.theme.colors.dark)};
`;

export const DetailTitle = styled.h3`
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailSubtitle = styled.h2`
  font-size: 1.3rem;
  font-weight: normal;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HorarioAtendimento = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    margin: 5px 0;
  }
`;

export const InfoIcon = styled(OriginalInfoIcon)`
  cursor: pointer;
  justify-self: center;
  margin-left: 5px;

  > path {
    color: ${props => props.theme.colors.primary} !important;
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  cursor: pointer;
  margin-right: 10px;
`;

export const ArrowForward = styled(OriginalArrowForwardIcon)`
  cursor: pointer;
  margin-left: 10px;
`;

export const LittleInfo = styled.span`
  margin-left: auto;
  font-size: 0.8rem;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const Footer = styled.div`
  margin-top: auto;
`;

export const PhoneIcon = styled(OriginalPhoneIcon)`
  margin-right: 10px;
`;

export const LancesContainer = styled.div`
  border-radius: 4px;
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  background-color: ${props => props.theme.colors.grayLight};
  flex-wrap: wrap;
  border: ${props => 'solid 7px ' + borderAlertColor[props.status] || ''};

  ${props => props.theme.breakPoints.hd} {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
`;
