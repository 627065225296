import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import {
  TableRow,
  Table,
  TableBody,
  TableHeader,
} from '@commons/components/Table';

import { AlertBadge, Crd } from './styles';
import { columns } from './utils';

const Sucatas = ({ bens, tipo, crd }) => {
  const formattedData = useMemo(() => {
    return map(bens, bem => {
      if (bem?.retirado === 'Y') {
        return {
          tipo: <AlertBadge>Retirado</AlertBadge>,
          descricao: {
            style: { textDecoration: 'line-through' },
            value: bem?.caracteristicas,
          },
          placa: {
            style: { textDecoration: 'line-through' },
            value: bem?.placa,
          },
          motor: {
            style: { textDecoration: 'line-through' },
            value: bem?.situacao_motor,
          },
          anoModelo: {
            style: { textDecoration: 'line-through' },
            value: bem?.ano_modelo,
          },
        };
      }

      return {
        tipo: bem?.nome,
        descricao: bem?.caracteristicas,
        placa: bem?.placa,
        motor: bem?.situacao_motor,
        anoModelo: bem?.ano_modelo,
      };
    });
  }, [bens]);

  return (
    <>
      {tipo === 'Detran' && !!crd && (
        <Crd textAlign="center">
          <b>CRD</b>: {crd}
        </Crd>
      )}
      {bens.length && (
        <Table>
          <TableHeader columns={columns} />
          <TableBody>
            {map(formattedData, (item, index) => (
              <TableRow key={index} data={item} columns={columns} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

Sucatas.propTypes = {
  bens: PropTypes.array.isRequired,
  tipo: PropTypes.string,
  crd: PropTypes.string,
};

export default Sucatas;
