import styled from 'styled-components';

export const AlertBadge = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  background-color: ${props => props.theme.colors.darkRed};
  border-radius: 4px;
  padding: 3px 10px;
  color: ${props => props.theme.colors.white};
`;

export const Crd = styled.div`
  font-size: 0.9rem;
`;
