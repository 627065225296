import lodashIsEmpty from 'lodash/isEmpty';
const { cnpj } = require('cpf-cnpj-validator');

/**
 * Validate if value is a valid cnpj
 * @param {String} value Param to do valid
 * @return {Object}
 * @property {Boolean} isValid `true` if value is a valid value
 * @property {String} errorText message of an invalid rule
 * */
const cnpjValidator = value => {
  const errorObject = {
    isValid: false,
    errorText: 'Cnpj inválido',
  };

  const successObject = {
    isValid: true,
  };

  if (lodashIsEmpty(value)) {
    return successObject;
  }

  if (!cnpj.isValid(value)) {
    return errorObject;
  }

  return successObject;
};

export default cnpjValidator;
