import lodashIsEmpty from 'lodash/isEmpty';

/**
 * Validate if value is true
 * @param {String} value Param to do valid
 *
 * @return {Object}
 * @property {Boolean} isValid `true` if value is a valid value
 * @property {String} errorText message of an invalid rule
 * */
const required = value => {
  const errorObject = {
    isValid: false,
    errorText: 'Este campo é obrigatório',
  };

  if (typeof value === 'number' && !lodashIsEmpty(String(value))) {
    return {
      isValid: true,
    };
  }

  if (lodashIsEmpty(value)) {
    return errorObject;
  }

  return {
    isValid: true,
  };
};

export default required;
