export const statusTexts = {
  9: 'warning', //Dou-lhe uma
  10: 'warning', //Dou-lhe duas
  4: 'warning', //Em disputa
  5: 'error', //Fechado
  6: 'error', //Vendido
  2: 'error', //Cancelado
  3: 'error', //Suspenso
  11: 'error', //Encerrado
};
