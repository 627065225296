import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import { logout } from '@commons/store/modules/auth/actions';

import ToolbarIcon from '../ToolbarIcon';
import { Footer, ExitIcon } from './styles';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const DrawerComponent = ({ open, handleDrawerClose, items }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <ToolbarIcon
        classes={classes}
        theme={theme}
        handleDrawerClose={handleDrawerClose}
      />
      <Divider />

      {items.map((list, index) => (
        <div key={index}>
          {list.map(({ title, icon: Icon, onClick }) => (
            <ListItem button onClick={onClick} key={title}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
          <Divider />
        </div>
      ))}

      <Footer>
        <Divider />
        <ListItem button onClick={handleLogout} key={'Sair'}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText primary={'Sair'} />
        </ListItem>
      </Footer>
    </Drawer>
  );
};

DrawerComponent.propType = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  items: PropTypes.array,
};

DrawerComponent.defaultProps = {
  items: [],
};

export default DrawerComponent;
