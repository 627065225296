import React from 'react';
import Slider from 'react-slick';

import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ children }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    dots: true,
    autoplay: true,
  };
  return <Slider {...settings}>{children}</Slider>;
};

Carousel.propTypes = { children: PropTypes.any };

export default Carousel;
