import lodashIsEmpty from 'lodash/isEmpty';

/**
 * Validate if value is a valid date
 * @param {String} value Param to do valid
 * @return {Object}
 * @property {Boolean} isValid `true` if value is a valid value
 * @property {String} errorText message of an invalid rule
 * */
const email = value => {
  const errorObject = {
    isValid: false,
    errorText: 'Email em formato inválido',
  };

  const successObject = {
    isValid: true,
  };

  if (lodashIsEmpty(value)) {
    return successObject;
  }

  const regex = /^[a-z0-9._-]+@[a-z0-9]+\.[a-z]+(\.([a-z]+))?$/i;

  if (!regex.test(value)) {
    return errorObject;
  }

  return successObject;
};

export default email;
