import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;

  .MuiAlert-icon path,
  .MuiAlert-message {
    color: white;
    font-weight: bold;
  }
`;
