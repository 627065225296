import styled from 'styled-components';

export const LogoImage = styled.img`
  height: 50px;
  cursor: pointer;

  padding-top: 5px;
`;

export const ClickableText = styled.div`
  cursor: pointer;
  user-select: none;
`;
