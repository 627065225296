import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: ${props => props.theme.colors.darker};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 1500px) {
    font-size: 3rem;
    padding: 20px 0;
  }
`;

export const TopArea = styled.div`
  flex-basis: 40%;
  display: flex;
  flex-shrink: 0;
  margin: 0 10px;

  > div {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    margin: 10px 10px;
    background-color: ${props => props.theme.colors.dark};
    box-shadow: -1px 1px 2px 4px ${props => props.theme.colors.darkGray};
    border-radius: 4px;
    font-size: 1.5rem;

    @media (max-width: 1600px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1500px) {
      font-size: 1.1rem;
    }
  }
`;

export const BottomArea = styled.div`
  flex-basis: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${props => props.theme.colors.darker};
  margin: 0 20px;

  > div {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: ${props => props.theme.colors.dark};
    box-shadow: -1px 1px 2px 4px ${props => props.theme.colors.darkGray};
    border-radius: 4px;
    font-size: 3.2rem;

    > span {
      padding: 0 10px;
    }

    @media (max-width: 1800px) {
      font-size: 2.6rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1500px) {
      height: 80px;
      font-size: 2rem;
    }
  }

  @media (max-width: 1600px) {
    padding: 20px 0;
  }
`;

export const UltimoLance = styled.div`
  margin-right: 10px;
  flex: 1;
`;

export const Comissao = styled.div`
  margin-left: 10px;
  flex: 1;
`;

export const Score = styled.span`
  font-family: 'LCD';
  color: ${props => (props.color ? props.color : props.theme.colors.red)};
`;

export const SegmentTitle = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.white};
  font-weight: bold;

  @media (max-width: 1400px) {
    font-size: 0.5rem;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    height: 200px;
  }
`;

export const SubTitle = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: 2rem;
  margin: 20px 20px 10px;

  @media (max-width: 1500px) {
    font-size: 1.5rem;
  }
`;

export const PredefinedButtonContainer = styled.div`
  display: flex;
  margin: 0 10px;

  > button {
    margin: 0 10px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
