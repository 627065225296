import React from 'react';

import PropTypes from 'prop-types';

import colors from '@commons/styles/colors';

import { Container, Paper, Header } from './styles';

const Card = ({ children, color, headerColor, headerText, ...props }) => (
  <Paper elevation={3}>
    <Header color={color} headerColor={headerColor}>
      {headerText}
    </Header>
    <Container>{children}</Container>
  </Paper>
);

Card.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  headerColor: PropTypes.string,
  headerText: PropTypes.string,
};

Card.defaultProps = {
  color: colors.white,
  headerColor: colors.primary,
  headerText: '',
};

export default Card;
