import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleIcon from '@material-ui/icons/People';
import RoomIcon from '@material-ui/icons/Room';

import { TableRow, Table, TableBody } from '@commons/components/Table';

import { TextWithIcon } from '../styles';
import { columns } from './utils';

const Imoveis = ({ bem }) => {
  const formattedData = useMemo(() => {
    return [
      {
        title: (
          <TextWithIcon>
            <CategoryIcon />
            Categoria:
          </TextWithIcon>
        ),
        content: bem?.categoria?.nome,
      },
      {
        title: (
          <TextWithIcon>
            <ClassIcon />
            Matrícula:
          </TextWithIcon>
        ),
        content: bem?.matricula,
      },
      {
        title: (
          <TextWithIcon>
            <RoomIcon />
            Endereço:
          </TextWithIcon>
        ),
        content: bem?.exposicao,
      },
      {
        title: (
          <TextWithIcon>
            <LocationCityIcon />
            Cidade/UF:
          </TextWithIcon>
        ),
        content: bem?.cidade,
      },
      {
        title: (
          <TextWithIcon>
            <PeopleIcon />
            Estado Ocupação:
          </TextWithIcon>
        ),
        content: bem?.estado_ocupacao,
      },
    ];
  }, [bem]);

  return (
    <>
      {bem && (
        <Table>
          <TableBody>
            {map(formattedData, (item, index) => (
              <TableRow key={index} data={item} columns={columns} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

Imoveis.propTypes = {
  bem: PropTypes.object.isRequired,
};

export default Imoveis;
