import { useState, useEffect } from 'react';

import { map, isEmpty } from 'lodash';

export const useForm = fields => {
  const [form, setForm] = useState();
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (fields && !form) {
      const newForm = {};

      map(fields, (field, key) => {
        newForm[key] = {
          required: false,
          error: false,
          errorMessage: '',
          value: '',
          rules: [],
          ...field,
        };
      });

      setForm(newForm);
    }
  }, [fields, form]);

  const updateInputValue = field => value => {
    setForm(oldForm => ({ ...oldForm, [field]: { ...oldForm[field], value } }));
  };

  const updateInputError = field => (errorMessage, error) => {
    setForm(oldForm => ({
      ...oldForm,
      [field]: {
        ...oldForm[field],
        errorMessage,
        error: error || oldForm[field].error,
      },
    }));
  };

  const validateForm = () => {
    const newForm = {};
    let formHasOneError = false;

    map(form, (field, key) => {
      const newField = getFieldError(field);

      if (newField.error) {
        formHasOneError = true;
      }

      newForm[key] = newField;
    });

    setForm(newForm);
    setFormIsValid(formHasOneError);

    return !formHasOneError;
  };

  const clearFields = () => {
    const newForm = {};

    map(fields, (field, key) => {
      newForm[key] = {
        error: false,
        errorMessage: '',
        value: '',
        ...newForm[key],
      };
    });

    setForm(newForm);
  };

  const getErrorMessage = field =>
    form[field].error && form[field].errorMessage
      ? form[field].errorMessage
      : '';

  const getFieldError = field => {
    const newField = { ...field };

    newField.error = false;
    newField.errorMessage = '';

    if (newField.rules.length) {
      map(newField.rules, rule => {
        let currentRule = rule(newField.value);

        if (!currentRule.isValid) {
          newField.error = true;
          newField.errorMessage = currentRule.errorText;
        }
      });
    }

    if (!!newField.errorMessage) {
      newField.error = true;
    }

    if (newField.required && isEmpty(newField.value)) {
      newField.error = true;
    }

    return newField;
  };

  return {
    form,
    formIsValid,
    validateForm,
    updateInputValue,
    updateInputError,
    getErrorMessage,
    clearFields,
  };
};
