import { all } from 'redux-saga/effects';

import admin from './admin/sagas';
import auth from './auth/sagas';
import faturas from './faturas/sagas';
import leiloes from './leiloes/sagas';
import lotes from './lotes/sagas';

export default function* rootSaga() {
  return yield all([leiloes, auth, lotes, admin, faturas]);
}
