import { takeLatest, all, call } from 'redux-saga/effects';

import api from '@commons/services/api';
import { showErrorAlert } from '@commons/utils/alert';

export function* getFaturas({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/documentos/faturas/minhasFaturas');

    resolve(response.data);
  } catch (err) {
    showErrorAlert({
      message: 'Erro ao buscar as faturas. Por favor, recarregue a página.',
    });

    reject(err);
  }
}

export function* downloadFatura({ payload }) {
  try {
    const { id, name } = payload;

    const { data } = yield call(api.get, `/documentos/faturas/download/${id}`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    showErrorAlert({
      message:
        'Erro ao baixar a fatura. Por favor, tente novamente mais tarde.',
    });
  }
}

export function* downloadDocumento({ payload }) {
  try {
    const { id, name } = payload;

    const { data } = yield call(
      api.get,
      `/documentos/downloadDocumentos/${id}`,
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    showErrorAlert({
      message:
        'Erro ao baixar a documento. Por favor, tente novamente mais tarde.',
    });
  }
}

export default all([
  takeLatest('@faturas/GET_FATURAS', getFaturas),
  takeLatest('@faturas/DOWNLOAD_FATURA', downloadFatura),
  takeLatest('@faturas/DOWNLOAD_DOCUMENTO', downloadDocumento),
]);
