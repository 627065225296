import produce from '@commons/utils/immer';

const INITIAL_STATE = {
  access_token: null,
  isAdmin: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/UPDATE_AUTH': {
        draft.access_token = action.payload;
        break;
      }
      case '@auth/LOGOUT': {
        draft.access_token = null;
        break;
      }
      case '@auth/SET_IS_ADMIN': {
        draft.isAdmin = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
