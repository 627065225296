import styled from 'styled-components';

export const Container = styled.div`
  width: 700px;
  height: auto;
  max-height: 450px;
  margin: 20px 0;
  align-self: center;

  @media (max-width: 1820px) {
    width: 600px;
  }

  @media (max-width: 1635px) {
    width: 500px;
  }

  @media (max-width: 1286px) {
    width: 400px;
  }

  ${props => props.theme.breakPoints.notebook} {
    width: 700px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 400px;
  user-select: none;
  cursor: grab;
  object-fit: scale-down;

  &:active {
    cursor: grabbing;
  }
`;

export const DetailsContainer = styled.div`
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
`;
