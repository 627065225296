import styled from 'styled-components';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const Container = styled.div`
  display: flex;
  padding: 0 30px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const DownloadIcon = styled(CloudDownloadIcon)`
  /* padding: 0 2px; */
  cursor: pointer;
`;
