import colors from '@commons/styles/colors';

export const statusColors = {
  1: colors.green,
  2: colors.red,
  3: colors.red,
  4: colors.darkYellow,
  5: colors.red,
  6: colors.red,
  7: colors.darkYellow,
  8: colors.red,
  9: colors.darkYellow,
  10: colors.orange,
  11: colors.red,
};

export const statusTexts = {
  1: 'Aberto',
  2: 'Cancelado',
  3: 'Suspenso',
  4: 'Em disputa',
  5: 'Fechado',
  6: 'Vendido',
  7: 'Sem Licitante',
  8: 'Arrematado',
  9: 'Dou-lhe uma',
  10: 'Dou-lhe duas',
  11: 'Encerrado',
};

export const statusParaFecharLances = [2, 3, 5, 6, 8, 11];
