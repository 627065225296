import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Crop75Icon from '@material-ui/icons/Crop75';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';

import { TableRow, Table, TableBody } from '@commons/components/Table';

import { TextWithIcon } from '../styles';
import { columns } from './utils';

const Veiculos = ({ bem, tipo, crd }) => {
  const formattedData = useMemo(() => {
    return [
      tipo === 'Detran' && {
        title: (
          <TextWithIcon>
            <HowToRegIcon />
            CRD:
          </TextWithIcon>
        ),
        content: crd,
      },
      {
        title: (
          <TextWithIcon>
            <CalendarTodayIcon />
            Ano Modelo:
          </TextWithIcon>
        ),
        content: bem?.ano_modelo,
      },
      {
        title: (
          <TextWithIcon>
            <LocalGasStationIcon />
            Combustível:
          </TextWithIcon>
        ),
        content: bem?.combustivel,
      },
      {
        title: (
          <TextWithIcon>
            <Crop75Icon />
            Placa:
          </TextWithIcon>
        ),
        content: bem?.placa,
      },
      {
        title: (
          <TextWithIcon>
            <ColorLensIcon />
            Cor:
          </TextWithIcon>
        ),
        content: bem?.cor,
      },
      {
        title: (
          <TextWithIcon>
            <DriveEtaIcon />
            Marca/Modelo:
          </TextWithIcon>
        ),
        content: `${bem.marca} - ${bem?.modelo}`,
      },
    ];
  }, [bem, tipo, crd]);

  return (
    <>
      {bem && (
        <Table>
          <TableBody>
            {map(formattedData, (item, index) => (
              <TableRow key={index} data={item} columns={columns} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

Veiculos.propTypes = {
  bem: PropTypes.object.isRequired,
  tipo: PropTypes.string,
  crd: PropTypes.string,
};

export default Veiculos;
