import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';

import Button from '@commons/components/Button';
import HtmlTooltip from '@commons/components/HtmlTooltip';
import Input from '@commons/components/Input';
import { resetPassword } from '@commons/store/modules/auth/actions';
import {
  showWarningAlert,
  showSuccessAlert,
  showErrorAlert,
} from '@commons/utils/alert';
import { emailValidator } from '@commons/validators';

import { Modal, CloseButton, Container, Content } from './styles';

export const ResetPasswordModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onButtonClick = () => {
    if (loading) {
      return;
    }

    const resetPasswordPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(resetPassword({ email }, resolve, reject));
      });
    };

    if (error) {
      return showWarningAlert({ message: error });
    }

    setLoading(true);

    resetPasswordPromise()
      .then(response => {
        showSuccessAlert({ message: response });
      })
      .catch(error => {
        showErrorAlert({ message: error });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
      aria-labelledby="recuperacao-senha"
      aria-describedby="recuperacao-de-senha"
    >
      <Container>
        <HtmlTooltip tooltipContent={'Fechar'}>
          <CloseButton
            onClick={onClose}
            variant="transparent"
            size="fit-content"
          >
            <CloseIcon />
          </CloseButton>
        </HtmlTooltip>
        <Content>
          <div>Digite o e-mail de sua conta para recuperar a senha:</div>
          <Input
            value={email}
            onChange={setEmail}
            onError={setError}
            label="E-mail"
            rules={[emailValidator]}
          />
          <Button size="full" onClick={onButtonClick}>
            Enviar
          </Button>
        </Content>
      </Container>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ResetPasswordModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default ResetPasswordModal;
