import React from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const Row = ({ data, columns }) => (
  <>
    <TableRow hover>
      {map(columns, (item, index) => {
        const itemIndex = item.dataKey;
        // Pure text
        if (typeof data[itemIndex] !== 'object') {
          return (
            <TableCell key={index} align={item.align || 'left'}>
              {!!item.mask ? item.mask(data[itemIndex]) : data[itemIndex]}
            </TableCell>
          );
        }

        if (Array.isArray(data[itemIndex])) {
          return (
            <TableCell
              key={index}
              align={item.align || 'left'}
              style={data[itemIndex]?.style}
            >
              {data[itemIndex]?.map((dataItem, i) => (
                <div>
                  {dataItem ? dataItem + ';' : 'Nenhum ponto vinculado'}
                  <br />
                </div>
              ))}
            </TableCell>
          );
        }

        // Text with style
        if (!item.contentIsReactComponent) {
          return (
            <TableCell
              key={index}
              align={item.align || 'left'}
              style={data[itemIndex]?.style}
            >
              {!!item.mask
                ? item.mask(data[itemIndex])
                : data[itemIndex]?.value}
            </TableCell>
          );
        }

        // React Component
        return (
          <TableCell key={index} align={item.align || 'left'}>
            {data[itemIndex]}
          </TableCell>
        );
      })}
    </TableRow>
  </>
);

Row.propTypes = {
  columns: PropTypes.array,
};

Row.defaultProps = {
  columns: [],
};

export default Row;
