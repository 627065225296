import swal from '@sweetalert/with-react';

const alert = ({
  title = 'Atenção',
  message = 'Ocorreu um erro, entre em contato com o administrador do sistema.',
  type,
}) => {
  swal(title, message, type, {
    buttons: 'Ok',
  });
};

export const showWarningAlert = ({ message }) => {
  alert({
    title: 'Atenção',
    type: 'warning',
    message,
  });
};

export const showSuccessAlert = ({
  message = 'Sucesso',
  title = 'Sucesso',
}) => {
  alert({
    title,
    type: 'success',
    message,
  });
};

export const showErrorAlert = ({
  message = 'Ocorreu um erro inesperado. Por favor, aguarde um momento e tente novamente.',
  title = 'Erro',
}) => {
  alert({
    title,
    type: 'error',
    message,
  });
};

export const confirmationDialog = async (
  title = 'Você tem certeza?',
  message
) => {
  let result = false;

  await swal({
    title,
    text: message,
    icon: 'warning',
    buttons: {
      cancel: 'Não',
      confirm: 'Sim',
    },
  }).then(response => {
    result = response;
  });

  return result;
};
