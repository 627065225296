import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { map } from 'lodash';

import {
  TableHeader,
  TableRow,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
} from '@commons/components/Table';
import Title from '@commons/components/Title';
import apllyDashboardTemplate from '@commons/containers/Dashboard';
import history from '@commons/services/history';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import { getLotes } from '@commons/store/modules/leiloes/actions';
import { statusColors } from '@commons/utils/constants';
import { currency } from '@commons/utils/formatters';

import { Container, Button, GavelIcon, AdminButton, Descricao } from './styles';
import { columns } from './utils';

const Lotes = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isAdmin } = useSelector(state => state.auth);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [lotes, setLotes] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const searchLotes = useCallback(
    (goToLancesAbertosPage = false) => {
      dispatch(setLoading(true));
      setTableIsLoading(true);

      const getLotesPromise = () => {
        return new Promise((resolve, reject) => {
          dispatch(getLotes({ id, page }, resolve, reject));
        });
      };

      getLotesPromise()
        .then(response => {
          setTotalItems(response.total);
          setTotalPages(response.last_page);

          const responseWithButton = map(response.data, item => {
            const color = statusColors[item.status_id];

            return {
              ...item,
              descricao: (
                <Descricao
                  dangerouslySetInnerHTML={{
                    __html:
                      item.caracteristicas || item.bens[0].caracteristicas,
                  }}
                />
              ),
              lance_atual: currency(item.lance_atual),
              lance_atual_5: currency(item.lance_atual_5),
              status: {
                value: item.status,
                style: {
                  color,
                  fontWeight: 'bold',
                },
              },
              actions: (
                <>
                  <Button
                    size="small"
                    onClick={() => history.push(`/lotes/${item.id}`)}
                  >
                    {isAdmin ? (
                      'Participar'
                    ) : (
                      <>
                        <GavelIcon /> Abrir
                      </>
                    )}
                  </Button>

                  {isAdmin && (
                    <AdminButton
                      size="small"
                      onClick={() => history.push(`/lotes/${item.id}/admin`)}
                    >
                      Gerenciar
                    </AdminButton>
                  )}
                </>
              ),
            };
          });

          setLotes(responseWithButton);

          if (goToLancesAbertosPage) {
            const lotesFechados = response.lotes_fechados;
            const itemsPerPage = 50;

            if (lotesFechados < itemsPerPage) return;

            const currentPage = Math.ceil(lotesFechados / itemsPerPage);

            setPage(currentPage);
          }
        })
        .finally(() => {
          dispatch(setLoading(false));
          setTableIsLoading(false);
        });
    },
    //eslint-disable-next-line
    [page]
  );

  useEffect(() => {
    if (isFirstLoad) {
      return setIsFirstLoad(false);
    }

    searchLotes();
    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    searchLotes(true);
    //eslint-disable-next-line
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container>
      <Title>Lotes disponíveis:</Title>
      {lotes && (
        <TableContainer>
          <Table>
            <TableHeader columns={columns} />
            <TableBody>
              {map(lotes, item => (
                <TableRow key={item.id} data={item} columns={columns} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            disabled={tableIsLoading}
            onPageChange={handlePageChange}
            totalPages={totalPages}
            total={totalItems}
            itemsByPage={50}
            page={page}
            label="lotes"
          />
        </TableContainer>
      )}
    </Container>
  );
};

export default apllyDashboardTemplate(Lotes);
