import styled from 'styled-components';

import LinearProgress from '@material-ui/core/LinearProgress';

export const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.gray};
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: normal;
  color: ${props => props.theme.colors.dark};
  margin-bottom: 10px;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  height: 430px;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.75);
  background-color: ${props => props.theme.colors.white};
  align-items: center;
  margin-bottom: 50px;

  > span {
    padding-top: 50px;
    font-size: 1rem;
  }

  ${props => props.theme.breakPoints.smallTablet} {
    width: 95%;
    padding: 0 20px 5px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 50px 0;
  width: 100%;

  > div {
    width: 100%;
    margin: 7px 0;
  }

  ${props => props.theme.breakPoints.smallTablet} {
    padding: 20px 0;
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const Loader = styled(LinearProgress)`
  width: calc(100% + 40px);
`;
