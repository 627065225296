import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';

import CurrencyTextField from '@commons/components/CurrencyInput';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import { enviarLance } from '@commons/store/modules/lotes/actions';
import { showWarningAlert } from '@commons/utils/alert';
import { confirmationDialog } from '@commons/utils/alert';
import { currency, toCurrency } from '@commons/utils/formatters';

import {
  Container,
  LanceContainer,
  PredefinedValues,
  PredefinedValueButton,
  PredefinedValuesContainer,
  Alert,
  Button,
  Footer,
  AddButton,
} from './styles';

export const RegistrarLance = ({
  incrementoMinimo,
  ultimoLance,
  lanceMinimoInicial,
  id,
}) => {
  const dispatch = useDispatch();
  const [lance, setLance] = useState('');
  const [visibleAlert, setVisibleAlert] = useState();
  const { loading } = useSelector(state => state.dashboard);

  useEffect(() => {
    setLance('');
  }, [id]);

  const formattedUltimoLance = useMemo(() => parseFloat(ultimoLance.valor), [
    ultimoLance,
  ]);

  const adicionarMinimo = () => {
    const valor = toCurrency(lance);

    const formattedValorMinimo = parseFloat(incrementoMinimo);

    if (valor === 0) {
      const formattedLanceMinimo = parseFloat(lanceMinimoInicial);

      const newLance =
        formattedUltimoLance > 0
          ? formattedValorMinimo + formattedUltimoLance
          : formattedValorMinimo + formattedLanceMinimo;

      return setLance(newLance);
    }

    setLance(valor + formattedValorMinimo);
  };

  const subtrairMinimo = () => {
    const valor = toCurrency(lance);
    const formattedValorMinimo = parseFloat(incrementoMinimo);

    if (valor < incrementoMinimo) {
      return setLance(0);
    }

    setLance(valor - formattedValorMinimo);
  };

  const setarPredefinido = value => {
    const formattedIncrementoMinimo = parseFloat(incrementoMinimo);

    const incremento = formattedIncrementoMinimo * value;

    if (formattedUltimoLance) {
      return setLance(formattedUltimoLance + incremento);
    }

    let valor =
      parseFloat(lanceMinimoInicial) + incremento - formattedIncrementoMinimo;

    return setLance(valor);
  };

  const doEnviarLance = async lance => {
    if (loading) {
      return;
    }

    const formattedLanceMinimo = parseFloat(lanceMinimoInicial);
    const formattedLance = parseFloat(lance);

    const lanceBase =
      formattedUltimoLance === 0 ? formattedLanceMinimo : formattedUltimoLance;

    if (lanceBase > 0 && formattedLance >= lanceBase * 1.5) {
      const response = await confirmationDialog(
        `Tem certeza que deseja enviar este lance: ${currency(
          formattedLance
        )}?`,
        `O lance indicado totaliza mais de 50% do valor do último lance.`
      );

      if (response === null) {
        return;
      }
    }

    dispatch(setLoading(true));

    const enviarDadosPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(enviarLance({ id, valor: lance }, resolve, reject));
      });
    };

    enviarDadosPromise()
      .then(response => {
        setLance('');
        handleAlert('success');
      })
      .catch(error => {
        if (
          error?.response?.data?.error ===
          'Impossível registrar. Mesmo Usuário!'
        ) {
          return showWarningAlert({ message: error.response.data.error });
        }

        if (error?.response?.status === 400) {
          return handleAlert('warning');
        }

        if (error?.response?.data?.error) {
          return showWarningAlert({ message: error.response.data.error });
        }

        return handleAlert('error');
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleAlert = type => {
    setVisibleAlert(type);

    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };

  const validarLance = lance => {
    if (isNaN(lance)) {
      return 'Lance inválido';
    }

    const formattedLanceMinimoInicial = parseFloat(lanceMinimoInicial);
    const formattedIncrementoMinimo = parseFloat(incrementoMinimo);

    if (lance < formattedLanceMinimoInicial) {
      return 'O valor precisa ser maior ou igual ao lance mínimo.';
    }

    if (formattedUltimoLance >= lance) {
      return 'O lance precisa ser maior que o último lance.';
    }

    if (lance - formattedUltimoLance < formattedIncrementoMinimo) {
      return 'O aumento tem que ser maior que o valor do incremento mínimo.';
    }

    return false;
  };

  const handleEnviarLanceButton = () => {
    const formattedLance = toCurrency(lance);

    const error = validarLance(formattedLance);

    if (error) {
      return showWarningAlert({ message: error });
    }

    doEnviarLance(formattedLance);
  };

  const valorPredefinido = useCallback(
    multiplicador => {
      const incremento = incrementoMinimo * multiplicador;

      if (formattedUltimoLance > 0) {
        return currency(formattedUltimoLance + incremento);
      }

      return currency(lanceMinimoInicial + incremento - incrementoMinimo);
    },
    [ultimoLance, lanceMinimoInicial, incrementoMinimo]
  );

  return (
    <>
      <Container elevation={5} alert={visibleAlert}>
        <LanceContainer>
          <AddButton
            variant="transparent"
            size="lance"
            onClick={subtrairMinimo}
          >
            - {currency(Number(incrementoMinimo))}
          </AddButton>
          <CurrencyTextField label="Lance" value={lance} onChange={setLance} />
          <AddButton
            variant="transparent"
            size="lance"
            onClick={adicionarMinimo}
          >
            + {currency(Number(incrementoMinimo))}
          </AddButton>
        </LanceContainer>
        <PredefinedValuesContainer>
          <PredefinedValues>
            <PredefinedValueButton
              size="lance"
              onClick={() => setarPredefinido(1)}
            >
              {valorPredefinido(1)}
            </PredefinedValueButton>
            <PredefinedValueButton
              size="lance"
              onClick={() => setarPredefinido(2)}
            >
              {valorPredefinido(2)}
            </PredefinedValueButton>
            <PredefinedValueButton
              size="lance"
              onClick={() => setarPredefinido(3)}
            >
              {valorPredefinido(3)}
            </PredefinedValueButton>
            <PredefinedValueButton
              size="lance"
              onClick={() => setarPredefinido(4)}
            >
              {valorPredefinido(4)}
            </PredefinedValueButton>
          </PredefinedValues>
        </PredefinedValuesContainer>
        <Footer>
          <Button size="enviarLance" onClick={handleEnviarLanceButton}>
            ENVIAR LANCE
          </Button>
        </Footer>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={visibleAlert === 'success'}
          onClose={() => setVisibleAlert(null)}
          autoHideDuration={6000}
          key={'snack-success'}
        >
          <Alert variant="filled" onClose={() => setVisibleAlert(null)}>
            Lance enviado com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={visibleAlert === 'warning'}
          onClose={() => setVisibleAlert(null)}
          autoHideDuration={6000}
          key={'snack-warning'}
        >
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => setVisibleAlert(null)}
          >
            Valor enviado inferior ao último lance dado.
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={visibleAlert === 'error'}
          onClose={() => setVisibleAlert(null)}
          autoHideDuration={6000}
          key={'snack-error'}
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setVisibleAlert(null)}
          >
            Erro ao enviar o lance. Por favor, tente novamente.
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

RegistrarLance.propTypes = {
  incrementoMinimo: PropTypes.string,
  ultimoLance: PropTypes.string,
  lanceMinimoInicial: PropTypes.number,
  id: PropTypes.string,
};

export default RegistrarLance;
