import styled from 'styled-components';

import OriginalGavelIcon from '@material-ui/icons/Gavel';

import OriginalButton from '@commons/components/Button';

export const Container = styled.div`
  display: flex;
  padding: 0 30px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const Button = styled(OriginalButton)`
  display: inherit;
`;

export const AdminButton = styled(Button)`
  display: inherit;
  background: ${props => props.theme.colors.darkYellow};
  margin-left: 10px;
`;

export const GavelIcon = styled(OriginalGavelIcon)`
  font-size: 0.9rem !important;
  margin-left: -5px;
  margin-right: 5px;

  > * {
    color: ${props => props.theme.colors.white};
  }
`;

export const Descricao = styled.div`
  max-width: 400px;
`;
