import styled from 'styled-components';

import OriginalAlert from '@material-ui/lab/Alert';

import OriginalButton from '@commons/components/Button';
import OriginalInput from '@commons/components/Input';

export const Container = styled.div`
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.darker};
`;

export const CardTitle = styled.h4`
  margin-top: 40px;
  font-size: 1.3rem;
`;

export const LanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PredefinedValuesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Input = styled(OriginalInput)`
  flex: 1;

  .MuiFormLabel-root {
    background: transparent;
  }

  .MuiInputBase-root,
  label {
    color: ${props => props.theme.colors.white};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.colors.darkGray};
  }

  .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.colors.white};
  }
`;

export const PredefinedValues = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const PredefinedValueButton = styled(OriginalButton)`
  background-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.dark};

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export const AddButton = styled(OriginalButton)`
  height: 55px;
  color: ${props => props.theme.colors.gray};
`;

export const Alert = styled(OriginalAlert)`
  margin-top: 20px;

  svg,
  path {
    color: ${props => props.theme.colors.white};
  }

  .MuiAlert-message {
    color: ${props => props.theme.colors.white};
  }
`;

export const Button = styled(OriginalButton)`
  height: 55px;
  background-color: ${props => props.theme.colors.darkerRed};
  font-size: 1.2rem;
  width: 200px;
`;

export const Footer = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
