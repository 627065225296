import styled, { css } from 'styled-components';

import OriginalEventIcon from '@material-ui/icons/Event';
import OriginalScheduleIcon from '@material-ui/icons/Schedule';

export const Title = styled.h3`
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: normal;
`;

export const Text = styled.span`
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const iconCss = css`
  font-size: 1.4rem;
  margin-right: 7px;
`;

export const EventIcon = styled(OriginalEventIcon)`
  ${iconCss};
`;

export const ScheduleIcon = styled(OriginalScheduleIcon)`
  ${iconCss};
`;

export const BoldText = styled.span`
  font-weight: bold;
  color: ${props => props.color};
`;

export const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    margin-bottom: 10px;
  }
`;
