import styled from 'styled-components';

import OriginalAlert from '@material-ui/lab/Alert';

import OriginalMultiselect from '@commons/components/Multiselect';

export const Container = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  align-self: center;
  height: 110px;
  width: 270px;
  margin-bottom: 20px;

  label {
    color: ${props => props.theme.colors.white} !important;
  }
`;

export const Multiselect = styled(OriginalMultiselect)`
  color: ${props => props.theme.colors.white} !important;
  width: 250px;

  fieldset {
    border-color: ${props => props.theme.colors.white} !important;

    legend {
      color: ${props => props.theme.colors.white} !important;
    }
  }
`;

export const Alert = styled(OriginalAlert)`
  margin-top: 20px;

  svg,
  path {
    color: ${props => props.theme.colors.white};
  }

  .MuiAlert-message {
    color: ${props => props.theme.colors.white};
  }
`;
