import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import { tituloPagina } from 'config';
import { ThemeProvider } from 'styled-components';

import Routes from '@commons/router';
import history from '@commons/services/history';
import { store, persistor } from '@commons/store';
import GlobalStyle from '@commons/styles/global';
import Theme from '@commons/styles/styled-component-theme';

function App() {
  document.title = tituloPagina;

  return (
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
