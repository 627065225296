import React from 'react';

import PropTypes from 'prop-types';

import {
  DetailTitle,
  TableContainer,
  DetailSubtitle,
  Text,
  ExposicaoContainer,
  ExposicaoTitle,
} from './styles';
import Imoveis from './Tables/Imoveis';
import Sucatas from './Tables/Sucatas';
import Veiculos from './Tables/Veiculos';

export const Detalhes = React.forwardRef(({ lote, noTitle }, ref) => {
  return (
    <>
      {!noTitle && (
        <>
          <DetailTitle>Lote {lote.numero}</DetailTitle>
          <DetailSubtitle textAlign="center">{lote.nome}</DetailSubtitle>
        </>
      )}

      {lote.sucata !== 'Y' && (
        <Text>
          <b>Características</b>:{' '}
          <span
            dangerouslySetInnerHTML={{ __html: lote.bens[0].caracteristicas }}
          ></span>
        </Text>
      )}

      <TableContainer>
        {lote.sucata === 'Y' ? (
          <Sucatas
            bens={lote.bens}
            tipo={lote.evento?.tipo?.nome}
            crd={lote.crd}
          />
        ) : (
          <>
            {lote.bens[0]?.categoria?.nome === 'VEÍCULOS' ? (
              <Veiculos
                bem={lote.bens[0]}
                tipo={lote.evento?.tipo?.nome}
                crd={lote.crd}
              />
            ) : (
              <Imoveis bem={lote.bens[0]} />
            )}
          </>
        )}
      </TableContainer>
      <ExposicaoContainer>
        <ExposicaoTitle>Exposição:</ExposicaoTitle>
        <Text>{lote.bens[0]?.exposicao}</Text>
      </ExposicaoContainer>
    </>
  );
});

Detalhes.propTypes = {
  lote: PropTypes.object.isRequired,
};

export default Detalhes;
