import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Pagination, PaginationDescription } from './styles';

const TablePagination = ({
  totalPages,
  page,
  onPageChange,
  disabled,
  itemsByPage,
  total,
  label,
}) => {
  const currentItems = useMemo(() => {
    const initial = (page - 1) * itemsByPage + 1;
    let final = initial + itemsByPage - 1;

    if (final > total) {
      final = total;
    }
    return [initial, final];
  }, [itemsByPage, page, total]);

  return (
    <>
      <Pagination
        disabled={disabled}
        onChange={onPageChange}
        hidePrevButton
        count={totalPages}
        page={page}
      />
      <PaginationDescription>
        {`${currentItems[0]}-${currentItems[1]}`} de {total} {label}
      </PaginationDescription>
    </>
  );
};

TablePagination.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  disabled: PropTypes.bool,
  itemsByPage: PropTypes.number,
  total: PropTypes.number,
  onPageChange: PropTypes.func,
  label: PropTypes.string,
};

TablePagination.defaultProps = {
  totalPages: 1,
  page: 1,
  disabled: false,
  itemsByPage: 10,
  total: 10,
  onPageChange: () => {},
  label: 'registros',
};

export default TablePagination;
