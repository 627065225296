import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const TableContainer = styled(Paper)`
  margin-top: 20px;
  height: 195px;
  overflow-y: auto;
`;

export const CardTitle = styled.h4`
  font-size: 1.3rem;
`;

export const Container = styled.div`
  margin: 40px 0;
  align-self: center;
  width: 100%;
`;
