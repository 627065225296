import React from 'react';

import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';

import Detalhes from '@commons/components/DetalhesLote';
import HtmlTooltip from '@commons/components/HtmlTooltip';

import { Modal, Button, DetalhesContainer } from './styles';

export const DetalhesModal = ({ lote, open, onClose }) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      aria-labelledby="detalhes-lote"
      aria-describedby="detalhes-do-lote"
    >
      <DetalhesContainer>
        <HtmlTooltip tooltipContent={'Fechar'}>
          <Button onClick={onClose} variant="transparent" size="fit-content">
            <CloseIcon />
          </Button>
        </HtmlTooltip>
        <Detalhes lote={lote} />
      </DetalhesContainer>
    </Modal>
  );
};

DetalhesModal.propTypes = {
  lote: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DetalhesModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default DetalhesModal;
