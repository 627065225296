export function signIn(payload, resolve, reject) {
  return {
    type: '@auth/SIGN_IN',
    payload,
    resolve,
    reject,
  };
}

export function updateAuth(payload) {
  return {
    type: '@auth/UPDATE_AUTH',
    payload,
  };
}

export function logout(payload) {
  return {
    type: '@auth/LOGOUT',
    payload,
  };
}

export function resetPassword(payload, resolve, reject) {
  return {
    type: '@auth/RESET_PASSWORD',
    payload,
    resolve,
    reject,
  };
}

export function getUserData(resolve, reject) {
  return {
    type: '@auth/GET_USER_DATA',
    resolve,
    reject,
  };
}

export function setIsAdmin(payload) {
  return {
    type: '@auth/SET_IS_ADMIN',
    payload,
  };
}
