import styled from 'styled-components';

export const DetailTitle = styled.h3`
  text-align: center;
  font-size: 1.3rem;
`;

export const DetailSubtitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: normal;
  margin: 10px 0 40px;
`;

export const TableContainer = styled.div`
  padding: 10px 20px 20px 0;
`;

export const Text = styled.span`
  margin: 10px 0;
  font-size: 0.9rem;
  text-align: ${props => (props.textAlign ? props.textAlign : 'left ')};
`;

export const ExposicaoContainer = styled.div`
  margin-top: 20px;
`;

export const ExposicaoTitle = styled.h3`
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 1.3rem;
`;
