import styled, { css } from 'styled-components';

import { TextField as TextFieldOriginal } from '@material-ui/core';

const StyleLight = css`
  .MuiFormLabel-root {
    background: ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.white};
    font-size: 0.9rem;
  }

  label.Mui-focused {
    color: ${props => props.theme.colors.white};
  }

  .MuiOutlinedInput-root {
    color: ${props => props.theme.colors.white};

    fieldset {
      border-color: ${props => props.theme.colors.white};
    }

    &:hover fieldset {
      border-color: ${props => props.theme.colors.white};
    }

    &.Mui-focused fieldset {
      border-color: ${props => props.theme.colors.white};
    }
  }

  .MuiFormHelperText-root.Mui-error {
    background: ${props => props.theme.colors.dark};
  }
`;

export const TextField = styled(TextFieldOriginal)`
  .MuiFormLabel-root {
    background: ${props => props.theme.colors.white};
  }

  .MuiFormHelperText-root.Mui-error {
    background: ${props => props.theme.colors.white};
    bottom: -7px;
    font-size: 0.6rem;
    left: 10px;
    margin: 0;
    padding: 0 5px;
    position: absolute;
  }

  ${props => props.light === 'true' && StyleLight}
`;
