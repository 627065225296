const onlyNumbers = value => {
  if (typeof value === 'number') {
    return value;
  }

  return value ? value.replace(/\D+/g, '') : '';
};

export const onlyCurrencyDigits = value => {
  value = String(value);
  value = value.replace(/[^0-9.,]/g, '');

  return value;
};

export const toCurrency = value => {
  if (!value) {
    return 0;
  }

  value = String(value);
  value = value.replace(/[^0-9.,]/g, '');
  // value = value.replace('.', '').replace(',', '.');

  return parseFloat(parseFloat(value).toFixed(2));
};

const currency = value => {
  let currency = isNaN(Number(value)) ? 0 : Number(value);

  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatter.format(currency);
};

const squareMeters = value => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    maximumSignificantDigits: 3,
  });

  return `${formatter.format(value)}m²`;
};

export { onlyNumbers, currency, squareMeters };
