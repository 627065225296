import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Title from '@commons/components/Title';
import apllyDashboardTemplate from '@commons/containers/Dashboard';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import { getLoteData } from '@commons/store/modules/lotes/actions';
import { statusColors } from '@commons/utils/constants';

import Lances from './Lances';
import { Container, Flag, Header } from './styles';

export const Lote = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isAdmin } = useSelector(state => state.auth);

  const [loteData, setLoteData] = useState();

  useEffect(() => {
    dispatch(setLoading(true));

    const getLoteDataPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(getLoteData({ id }, resolve, reject));
      });
    };

    getLoteDataPromise()
      .then(response => {
        setLoteData(response);
      })
      .catch(error => console.error(error))
      .finally(() => dispatch(setLoading(false)));
    //eslint-disable-next-line
  }, [id]);

  return (
    <>
      {!!loteData && isAdmin && (
        <>
          <Header>
            <Flag color={statusColors[loteData.status_id]}>
              {loteData?.status?.nome}
            </Flag>
            <Title>{loteData.evento?.nome}</Title>
          </Header>
          <Container>
            <Lances id={id} setLote={setLoteData} lote={loteData} />
          </Container>
        </>
      )}
    </>
  );
};

export default apllyDashboardTemplate(Lote);
