import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { mudarStatusLote } from '@commons/store/modules/admin/actions';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import { confirmationDialog } from '@commons/utils/alert';
import { statusParaFecharLances } from '@commons/utils/constants';

import {
  Container,
  DoulheUmaButton,
  DoulheDuasButton,
  FecharButton,
  ReabrirButton,
  ClockContainer,
  GavelIcon,
} from './styles';

export const BatidasMartelo = ({ lote }) => {
  const dispatch = useDispatch();
  const [countdownTime, setCountdownTime] = useState(0);
  const [countdownController, setCountdownController] = useState();

  const startTimer = () => {
    clearInterval(countdownController);
    setCountdownTime(15);

    setCountdownController(() => {
      return setInterval(() => {
        setCountdownTime(prevState => {
          if (prevState > 0) return --prevState;

          clearInterval(countdownController);
          return prevState;
        });
      }, 1000);
    });
  };

  const mudarStatus = status_id => {
    const mudarStatusPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(mudarStatusLote({ id: lote.id, status_id }, resolve, reject));
      });
    };

    dispatch(setLoading(true));

    mudarStatusPromise()
      .then(response => {
        if (status_id === 9 || status_id === 10) {
          startTimer();
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const reabrirLote = async () => {
    const response = await confirmationDialog(
      `Tem certeza que deseja reabrir o lote?`
    );

    if (response === true) {
      mudarStatus(1);
    }
  };

  return (
    <Container>
      {(lote.status_id === 1 || lote.status_id === 4) && (
        <DoulheUmaButton onClick={() => mudarStatus(9)}>
          <GavelIcon />
          &nbsp;Dou-lhe uma
        </DoulheUmaButton>
      )}
      {lote.status_id === 9 && (
        <>
          <DoulheDuasButton onClick={() => mudarStatus(10)}>
            <GavelIcon />
            &nbsp;Dou-lhe duas
          </DoulheDuasButton>
        </>
      )}
      {lote.status_id === 10 && (
        <FecharButton onClick={() => mudarStatus(5)}>
          <GavelIcon />
          &nbsp;Dou-lhe três
        </FecharButton>
      )}
      {(lote.status_id === 10 || lote.status_id === 9) && (
        <ClockContainer>{countdownTime}</ClockContainer>
      )}
      {statusParaFecharLances.indexOf(lote.status_id) !== -1 && (
        <ReabrirButton onClick={reabrirLote}>Reabrir lote</ReabrirButton>
      )}
    </Container>
  );
};

BatidasMartelo.propTypes = {
  lances: PropTypes.array,
};

export default BatidasMartelo;
