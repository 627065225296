import React from 'react';

import { Table, Container } from './styles';

const CustomTable = ({ children, loading }) => (
  <Container>
    <Table>{children}</Table>
  </Container>
);

export default CustomTable;
