export const metodosPagamento = [
  'À vista',
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x',
  '11x',
  '12x',
  '20x',
  '24x',
  '30x',
  '40x',
  '48x',
  '50x',
  '60x',
];
