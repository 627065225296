import React from 'react';

import OriginalAlert from '@material-ui/lab/Alert';

import { Container } from './styles';

const Alert = ({ children, ...props }) => {
  return (
    <Container>
      <OriginalAlert variant="filled" {...props}>
        {children}
      </OriginalAlert>
    </Container>
  );
};

export default Alert;
