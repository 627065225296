import React, { useState } from 'react';

import DocumentsIcon from '@material-ui/icons/FileCopy';
import GavelIcon from '@material-ui/icons/Gavel';

import history from '@commons/services/history';

import Drawer from '../Drawer';
import Toolbar from '../Toolbar';

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const sideBarItems = [
    [
      {
        title: 'Leilões disponíveis',
        icon: GavelIcon,
        onClick: () => history.push('/leiloes'),
      },
      {
        title: 'Faturas',
        icon: DocumentsIcon,
        onClick: () => history.push('/faturas'),
      },
    ],
  ];

  return (
    <>
      <Toolbar open={open} handleDrawerOpen={() => setOpen(true)} />
      <Drawer
        open={open}
        handleDrawerClose={() => setOpen(false)}
        items={sideBarItems}
      />
    </>
  );
};

export default Sidebar;
