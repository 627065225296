import React from 'react';

import Button from '@commons/components/Button';
import Card from '@commons/components/Card';

import {
  Title,
  Text,
  ScheduleIcon,
  EventIcon,
  BoldText,
  Footer,
} from './styles';

export const LeilaoCard = ({ leilao, onClick }) => {
  return (
    <>
      <Card headerColor={leilao.color} headerText={leilao.eventStatus}>
        <Title>{leilao.nome}</Title>
        <Text>
          Status:{' '}
          <BoldText color={leilao.color}>{'\u00a0' + leilao.status}</BoldText>
        </Text>
        <Text>
          <ScheduleIcon></ScheduleIcon>
          {leilao.hora}
        </Text>
        <Text>
          <EventIcon></EventIcon>
          {leilao.dia}
        </Text>
        <Footer>
          <Button onClick={onClick}>Ver lotes</Button>
        </Footer>
      </Card>
    </>
  );
};

export default LeilaoCard;
