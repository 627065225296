import React from 'react';
import { useSelector } from 'react-redux';

import Logo from 'assets/images/logo.png';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import history from '@commons/services/history';
import colors from '@commons/styles/colors';

import { LogoImage } from './styles';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: colors.grayLight,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: colors.white,
  },
  hide: {
    display: 'none',
  },
}));

const ToolbarComponent = ({ open, handleDrawerOpen }) => {
  const classes = useStyles();

  const { loading } = useSelector(state => state.dashboard);

  const handleLogoClick = () => {
    history.push('/leiloes');
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar open={true}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          <LogoImage
            src={Logo}
            alt="Logo Tradeleilões"
            onClick={handleLogoClick}
          />
        </Typography>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
};

Toolbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func,
};

export default ToolbarComponent;
