import produce from '@commons/utils/immer';

const INITIAL_STATE = {
  loading: false,
};

export default function dashboard(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@dashboard/SET_LOADING': {
        draft.loading = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
