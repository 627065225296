import styled from 'styled-components';

import OriginalTable from '@material-ui/core/Table';

export const Table = styled(OriginalTable)`
  th {
    color: ${props => props.theme.colors.grayDark};
    font-weight: normal;
  }

  th,
  td {
    font-size: 0.8rem;
    padding: 10px;
  }

  thead {
    tr {
      border-bottom: 2px solid ${props => props.theme.colors.tableGray};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
