import { createGlobalStyle } from 'styled-components';

import LCD from '@commons/fonts/LCDMonoWinTT/LCD2B___.TTF';

import colors from './colors';

export default createGlobalStyle`
  @font-face {
    font-family: 'LCD';
    src: url(${LCD}) format('truetype');
  }

  .slick-prev:before, .slick-next:before {
    color: ${colors.dark};
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    color: ${colors.dark};
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    width: 100%;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  button {
    cursor: pointer;
  }

`;
