export default {
  primary: '#044B7F',
  gray: '#EAEAEA',
  grayLight: '#f8f9fa',
  dark: '#313131',
  darker: '#111111',
  black: '#000000',
  white: '#ffffff',
  red: '#f44336',
  darkRed: '#f91b1b',
  darkRedSecondary: '#ff1919',
  darkerRed: '#b71c1c',
  blue: '#3e49a5',
  tableGray: '#E4E7EB',
  darkYellow: '#f9a825',
  yellow: '#f9d826',
  rgbaDark: 'rgba(0, 0, 0, 0.3)',
  green: '#0A8754',
  greenLight: '#0b9632',
  greenLighter: '#00ff00',
  darkGray: '#505050',
  middleBlue: '#044B7F',
  orange: '#ef6c00',
};
