export function getFaturas(resolve, reject) {
  return {
    type: '@faturas/GET_FATURAS',
    resolve,
    reject,
  };
}

export function downloadFatura(payload, resolve, reject) {
  return {
    type: '@faturas/DOWNLOAD_FATURA',
    payload,
  };
}

export function downloadDocumento(payload) {
  return {
    type: '@faturas/DOWNLOAD_DOCUMENTO',
    payload,
  };
}
