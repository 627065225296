import React from 'react';

import PropTypes from 'prop-types';

import { TableBody } from './styles';

const CustomTableBody = ({ children, loading }) => (
  <TableBody isloading={String(loading)}>{children}</TableBody>
);

CustomTableBody.propTypes = {
  loading: PropTypes.bool,
};

CustomTableBody.defaultProps = {
  loading: false,
};

export default CustomTableBody;
