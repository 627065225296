import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { map } from 'lodash';

import HtmlTooltip from '@commons/components/HtmlTooltip';
import {
  TableHeader,
  TableRow,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
} from '@commons/components/Table';
import Title from '@commons/components/Title';
import apllyDashboardTemplate from '@commons/containers/Dashboard';
import history from '@commons/services/history';
import { setLoading } from '@commons/store/modules/dashboard/actions';
import {
  downloadDocumento,
  downloadFatura,
  getFaturas,
} from '@commons/store/modules/faturas/actions';
import { getLotes } from '@commons/store/modules/leiloes/actions';
import { statusColors } from '@commons/utils/constants';

import { Container, DownloadIcon } from './styles';
import { columns } from './utils';

const Lotes = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isAdmin } = useSelector(state => state.auth);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [faturas, setFaturas] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const doDownloadFatura = useCallback(async (id, name) => {
    dispatch(downloadFatura({ id, name }));
  }, []);

  const doDownloadDocumento = useCallback(async (id, name) => {
    dispatch(downloadDocumento({ id, name }));
  }, []);

  const formatFaturas = faturas => {
    return faturas.map(item => {
      const { id, numero, hash, created_at, lote, documentos } = item;

      return {
        id,
        numero,
        hash,
        created_at,
        eventName: lote?.evento?.nome,
        loteName: lote?.nome,
        fatura: (
          <HtmlTooltip key={id} tooltipContent={`FATURA`}>
            <DownloadIcon
              style={{ padding: 1 }}
              onClick={() => doDownloadFatura(id, 'fatura.pdf')}
            />
          </HtmlTooltip>
        ),
        files: (
          <>
            {documentos.map(file => (
              <HtmlTooltip
                key={file.id}
                tooltipContent={`(${file.nome}) - ${file.arquivo}`}
              >
                <DownloadIcon
                  style={{ padding: 1 }}
                  onClick={() => doDownloadDocumento(file.id, file.arquivo)}
                />
              </HtmlTooltip>
            ))}
          </>
        ),
      };
    });
  };

  const searchFaturas = useCallback(() => {
    dispatch(setLoading(true));
    setTableIsLoading(true);

    const getFaturasPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(getFaturas(resolve, reject));
      });
    };

    getFaturasPromise()
      .then(response => {
        setFaturas(formatFaturas(response));
      })
      .finally(() => {
        dispatch(setLoading(false));
        setTableIsLoading(false);
      });
  }, []);

  useEffect(() => {
    searchFaturas();
  }, []);

  return (
    <Container>
      <Title>Faturas disponíveis:</Title>
      {faturas && (
        <TableContainer>
          <Table>
            <TableHeader columns={columns} />
            <TableBody>
              {map(faturas, item => (
                <TableRow key={item.id} data={item} columns={columns} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default apllyDashboardTemplate(Lotes);
