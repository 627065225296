import lodashIsEmpty from 'lodash/isEmpty';
const { cpf } = require('cpf-cnpj-validator');

/**
 * Validate if value is a valid cpf
 * @param {String} value Param to do valid
 * @return {Object}
 * @property {Boolean} isValid `true` if value is a valid value
 * @property {String} errorText message of an invalid rule
 * */
const cpfValidator = value => {
  const errorObject = {
    isValid: false,
    errorText: 'Cpf inválido',
  };

  const successObject = {
    isValid: true,
  };

  if (lodashIsEmpty(value)) {
    return successObject;
  }

  if (!cpf.isValid(value)) {
    return errorObject;
  }

  return successObject;
};

export default cpfValidator;
