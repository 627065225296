import styled from 'styled-components';

import OriginalAlert from '@material-ui/lab/Alert';

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.darker};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 1400px) {
    font-size: 3rem;
    padding: 20px 0;
  }
`;

export const TopArea = styled.div`
  flex-basis: 40%;
  display: flex;
  flex-shrink: 0;

  > div {
    display: flex;
    flex-basis: 38%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    margin: 10px 20px;
    background-color: ${props => props.theme.colors.dark};
    box-shadow: -1px 1px 2px 4px ${props => props.theme.colors.darkGray};
    border-radius: 4px;
    font-size: 2rem;

    @media (max-width: 1600px) {
      font-size: 1.8rem;
    }

    @media (max-width: 1500px) {
      font-size: 1.5rem;
    }
  }
`;

export const CondicaoPagamentoDiv = styled.div`
  flex-basis: 24% !important;
`;

export const BottomArea = styled.div`
  flex-basis: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${props => props.theme.colors.darker};
  margin: 0 20px;

  > div {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: ${props => props.theme.colors.dark};
    box-shadow: -1px 1px 2px 4px ${props => props.theme.colors.darkGray};
    border-radius: 4px;
    font-size: 3.2rem;

    > span {
      padding: 0 10px;
    }

    @media (max-width: 1800px) {
      font-size: 2.6rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1500px) {
      height: 80px;
      font-size: 2rem;
    }
  }

  @media (max-width: 1600px) {
    padding: 20px 0;
  }
`;

export const UltimoLance = styled.div`
  margin-right: 40px;
  flex: 1.5;

  p {
    color: ${props => props.theme.colors.yellow};
    font-size: 24px;
  }

  @media (max-width: 1600px) {
    p {
      font-size: 20px;
    }
  }
`;

export const Comissao = styled.div`
  flex: 1;
`;

export const Score = styled.span`
  font-family: 'LCD';
  color: ${props => (props.color ? props.color : props.theme.colors.red)};
`;

export const CondicaoPagamento = styled.span`
  font-size: 1.7rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 1rem;
  }
`;

export const SegmentTitle = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  text-align: center;

  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    height: 180px;
  }
`;

export const SubTitle = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: 2rem;
  margin: 20px 20px 10px;

  @media (max-width: 1500px) {
    font-size: 1.5rem;
  }
`;

export const H4Title = styled.h4`
  color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  margin: 20px 20px 10px;

  @media (max-width: 1500px) {
    font-size: 1.2rem;
  }
`;

export const PredefinedButtonContainer = styled.div`
  display: flex;
  margin: 0 10px;

  > button {
    margin: 0 10px;
  }
`;

export const BidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px 0;
`;

export const Alert = styled(OriginalAlert)`
  margin-top: 20px;

  svg,
  path {
    color: ${props => props.theme.colors.white};
  }

  .MuiAlert-message {
    color: ${props => props.theme.colors.white};
  }
`;
