import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Slider from '@material-ui/core/Slider';
import Snackbar from '@material-ui/core/Snackbar';

import CurrencyTextField from '@commons/components/CurrencyInput';
import HtmlTooltip from '@commons/components/HtmlTooltip';
import api from '@commons/services/api';
import colors from '@commons/styles/colors';
import { showErrorAlert } from '@commons/utils/alert';

import {
  Modal,
  CloseButton,
  DetalhesContainer,
  CloseIcon,
  LanceContainer,
  EnviarLanceButton,
} from './styles';

export const LanceAutomatizadoModal = ({
  open,
  onClose,
  onSuccess,
  incrementoMinimo,
  ultimoLance,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [lance, setLance] = useState(
    Number(ultimoLance.valor) + Number(incrementoMinimo) * 2
  );

  const handleEnviarLance = async () => {
    setLoading(true);

    try {
      const response = await api.post(`/lotes/lances/registraAutomatizado`, {
        lote_id: id,
        valor: lance,
      });

      onSuccess(response.data);
      onClose();
    } catch (error) {
      showErrorAlert({
        message:
          'Error ao cadastrar o lance automatizado. Por favor, tente mais tarde ou entre em contato com o plantão do leilão.',
      });
    }

    setLoading(false);
  };

  const handleSliderChange = (event, newValue) => {
    setLance(newValue);
  };

  const menorLanceAutomatizado =
    Number(ultimoLance.valor) + Number(incrementoMinimo) * 2;
  const maiorLanceAutomatizado =
    Number(ultimoLance.valor) + Number(incrementoMinimo) * 100;

  return (
    <Modal
      onClose={onClose}
      open={open}
      aria-labelledby="lance-automatizado"
      aria-describedby="lance-automatizado"
    >
      <DetalhesContainer>
        <HtmlTooltip tooltipContent={'Fechar'}>
          <CloseButton
            onClick={onClose}
            variant="transparent"
            size="fit-content"
          >
            <CloseIcon />
          </CloseButton>
        </HtmlTooltip>
        <LanceContainer>
          <h3>Lance automatizado</h3>
          <span className="lance-automatizado-description">
            Arraste a régua para selecionar o valor máximo do seu lance
            automatizado:
          </span>
          <h1>R$ {lance}</h1>
          <Slider
            step={incrementoMinimo}
            marks
            min={menorLanceAutomatizado}
            max={maiorLanceAutomatizado}
            defaultValue={menorLanceAutomatizado}
            onChange={handleSliderChange}
            value={lance}
          />
          <EnviarLanceButton
            disabled={loading}
            size="enviarLance"
            onClick={handleEnviarLance}
          >
            ENVIAR LANCE
          </EnviarLanceButton>
        </LanceContainer>
        {/* <Detalhes lote={lote} /> */}
      </DetalhesContainer>
    </Modal>
  );
};

LanceAutomatizadoModal.propTypes = {
  lote: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

LanceAutomatizadoModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default LanceAutomatizadoModal;
