import styled from 'styled-components';

import OriginalGavelIcon from '@material-ui/icons/Gavel';

import OriginalButton from '@commons/components/Button';

export const Container = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  align-self: center;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Button = styled(OriginalButton)`
  height: 80px;
  width: 200px;
  font-size: 1.5rem;
`;

export const DoulheUmaButton = styled(Button)`
  background: ${props => props.theme.colors.darkYellow};
`;

export const DoulheDuasButton = styled(Button)`
  background: ${props => props.theme.colors.orange};
`;

export const FecharButton = styled(Button)`
  background: ${props => props.theme.colors.darkRed};
`;

export const ReabrirButton = styled(Button)`
  background: ${props => props.theme.colors.primary};
`;

export const ClockContainer = styled.div`
  height: 100px;
  width: 100px;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  margin-left: 20px;
`;

export const GavelIcon = styled(OriginalGavelIcon)`
  path {
    color: ${props => props.theme.colors.white};
  }
`;
