import React from 'react';

import PropTypes from 'prop-types';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import colors from '@commons/styles/colors';

import { TextField } from './styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.green },
  },

  typography: {
    fontSize: 15,
  },

  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: 'red',
      },
    },
  },
});

const Input = ({ label, value, onChange }) => {
  const handleOnChange = (event, value) => {
    return onChange(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={label}
        variant="outlined"
        value={value}
        onChange={handleOnChange}
        decimalCharacter=","
        digitGroupSeparator="."
        currencySymbol="R$"
      />
    </ThemeProvider>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

Input.defaultProps = {
  onChange: () => {},
};

export default Input;
