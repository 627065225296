import styled from 'styled-components';

import OriginalDragHandleIcon from '@material-ui/icons/DragHandle';

export const DragButton = styled.div`
  cursor: grab;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  border-bottom-right-radius: 10px;

  &:active {
    cursor: grabbing;
  }
`;

export const DragHandleIcon = styled(OriginalDragHandleIcon)`
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
`;
