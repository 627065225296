import React from 'react';

import PropTypes from 'prop-types';

import Alert from '@commons/components/Alert';

import { statusTexts } from './utils';

const Alerts = ({ status, status_id, sucata }) => {
  return (
    <>
      {!!sucata && (
        <Alert severity="info">
          Lotes de sucatas só podem receber lances de CDVs
        </Alert>
      )}
      {!!statusTexts[status_id] && (
        <Alert severity={statusTexts[status_id]}>{status}</Alert>
      )}
    </>
  );
};

Alerts.propTypes = { status: PropTypes.string, status_id: PropTypes.number };

export default Alerts;
