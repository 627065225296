import styled from 'styled-components';

import OriginalPaper from '@material-ui/core/Paper';

export const Paper = styled(OriginalPaper)`
  width: 300px;
  height: 340px;
  margin: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 300px;
`;

export const Header = styled.div`
  width: 100%;
  height: 40px;
  background: ${props => props.headerColor};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${props => props.color};
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
`;
