export const columns = [
  {
    title: 'Tipo',
    dataKey: 'tipo',
    contentIsReactComponent: true,
  },
  {
    title: 'Descrição',
    dataKey: 'descricao',
  },
  {
    title: 'Placa',
    dataKey: 'placa',
  },
  {
    title: 'Motor',
    dataKey: 'motor',
  },
  {
    title: 'Ano Modelo',
    dataKey: 'anoModelo',
  },
];
