import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { nomeEmpresa } from 'config';

import Button from '@commons/components/Button';
import Input from '@commons/components/Input';
import { useForm } from '@commons/components/Input/hooks';
import history from '@commons/services/history';
import { signIn, getUserData } from '@commons/store/modules/auth/actions';
import { showWarningAlert } from '@commons/utils/alert';
import handleError from '@commons/utils/handleError';
import { requiredValidator } from '@commons/validators';

import ResetPasswordModal from './ResetPasswordModal';
import { Container, Title, LoginBox, Form, Footer, Loader } from './styles';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    form,
    validateForm,
    updateInputValue,
    updateInputError,
    getErrorMessage,
  } = useForm({
    user: { value: '', required: true, rules: [requiredValidator] },
    password: {
      value: '',
      required: true,
      rules: [requiredValidator],
    },
  });

  const handleSubmit = e => {
    e.preventDefault();

    const formIsValid = validateForm();

    if (!formIsValid) {
      return showWarningAlert({
        message: 'Preencha todos os campos para continuar',
      });
    }

    setLoading(true);

    const sighInPromise = () => {
      const data = {
        username: form.user.value,
        password: form.password.value,
      };

      return new Promise((resolve, reject) => {
        dispatch(signIn(data, resolve, reject));
      });
    };

    const getUserDataPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(getUserData(resolve, reject));
      });
    };

    sighInPromise()
      .then(response => {
        getUserDataPromise().then(() => {
          history.push('leiloes');
        });
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleResetPassword = e => {
    e.preventDefault();

    setResetModalOpen(true);
  };

  return (
    <Container>
      <Title>
        <b>{nomeEmpresa}</b>Leilões
      </Title>
      <LoginBox>
        {loading && <Loader />}
        <span>Preencha usuário e senha para continuar</span>
        {form && (
          <Form autoComplete="on" onSubmit={handleSubmit}>
            <Input
              label="Usuário"
              value={form.user.value}
              onChange={updateInputValue('user')}
              onError={updateInputError('user')}
              forceErrorText={getErrorMessage('user')}
            />

            <Input
              label="Senha"
              type="password"
              value={form.password.value}
              onChange={updateInputValue('password')}
              onError={updateInputError('password')}
              forceErrorText={getErrorMessage('password')}
            />

            <Footer>
              <Button type="submit" size="full" disabled={loading}>
                Entrar
              </Button>
              <Button
                size="full"
                variant="transparent"
                disabled={loading}
                onClick={handleResetPassword}
              >
                Esqueceu sua senha?
              </Button>
            </Footer>
          </Form>
        )}
      </LoginBox>
      <ResetPasswordModal
        open={resetModalOpen}
        onClose={() => setResetModalOpen(false)}
      />
    </Container>
  );
};

export default Login;
