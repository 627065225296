import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import colors from '@commons/styles/colors';

import { TextField } from './styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.green },
  },

  typography: {
    fontSize: 15,
  },

  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: 'red',
      },
    },
  },
});

const Input = ({
  light,
  forceErrorText,
  className,
  label,
  value,
  onChange,
  mask,
  maxLength,
  rules,
  onError,
  ...rest
}) => {
  const [errorToShow, setErrorToShow] = useState(false);
  const [wasRendered, setWasRendered] = useState(false);

  const handleOnChange = event => {
    let value = event.target.value;

    if (mask) {
      value = mask(value);
    }

    const error = validateInput(value);
    onError(error);

    return onChange(value);
  };

  const validateInput = useCallback(
    validatingValue => {
      let error = false;

      if (rules.length) {
        map(rules, rule => {
          let currentRule = rule(validatingValue);

          if (!currentRule.isValid) {
            error = currentRule.errorText;
            return;
          }
        });
      }

      return error;
    },
    [rules]
  );

  const handleOnBlur = useCallback(() => {
    const error = validateInput(value);
    setErrorToShow(error);
  }, [validateInput, value]);

  useEffect(() => {
    if (!wasRendered) {
      const error = validateInput(value);

      if (!!value) {
        handleOnBlur();
      }

      onError(error);
      setWasRendered(true);
    }
  }, [handleOnBlur, onError, validateInput, value, wasRendered]);

  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...rest}
        light={light && 'true'}
        className={className}
        error={!!forceErrorText || !!errorToShow}
        label={label}
        variant="outlined"
        value={value}
        onChange={handleOnChange}
        inputProps={{
          maxLength: maxLength,
        }}
        onBlur={handleOnBlur}
        helperText={forceErrorText || errorToShow}
        onError={value => {
          return value;
        }}
      />
    </ThemeProvider>
  );
};

Input.propTypes = {
  light: PropTypes.bool,
  forceErrorText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  mask: PropTypes.func,
  maxLength: PropTypes.number,
  rules: PropTypes.array,
  onError: PropTypes.func,
};

Input.defaultProps = {
  onChange: () => {},
  rules: [],
  onError: () => {},
};

export default Input;
