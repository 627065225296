import styled, { css } from 'styled-components';

const sizes = {
  small: css`
    width: 108px;
    height: 33px;
  `,
  default: css`
    width: 175px;
    height: 40px;

    @media (max-width: 425px) {
      width: 108px;
      height: 33px;
    }
  `,
  large: css`
    width: 280px;
    height: 50px;
  `,

  extra: css`
    width: 201px;
    height: 60px;

    @media (max-width: 425px) {
      width: 175px;
      height: 60px;
    }
  `,

  full: css`
    width: 100%;
    height: 50px;
  `,

  lance: css`
    height: 40px;
    width: 130px;

    @media (max-width: 1500px) {
      width: 110px;
      font-size: 0.8rem;
    }
  `,

  enviarLance: css`
    height: 40px;
    width: 130px;
  `,

  'fit-content': css`
    width: auto;
    padding: 10px;
  `,
};

const types = {
  default: css`
    border: none;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  `,

  outline: css`
    background: transparent;
    color: ${props => props.theme.colors.dark};
    border: ${props => `solid 1px ${props.theme.colors.dark}`};
  `,

  transparent: css`
    background: transparent;
    color: ${props => props.theme.colors.dark};
    border: none;

    &:hover {
      text-decoration: underline;
    }
  `,
};

const ButtonStyle = css`
  border-radius: 5px;
  outline: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  max-width: 100%;

  ${props =>
    props.disabled && `background: ${props.theme.colors.gray} !important`};

  ${props => sizes[props.size || 'default']};
  ${props => types[props.variant || 'default']};
`;

const Button = styled.button`
  ${ButtonStyle}
`;

export const ButtonLink = styled.a`
  ${ButtonStyle}
`;

export default Button;
