import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import colors from '@commons/styles/colors';

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.primary },
  },

  typography: {
    fontSize: 18,
  },
});

const Multiselect = ({
  label,
  value,
  onChange,
  forceErrorText,
  onError,
  required,
  options,
  ...props
}) => {
  const [error, setError] = useState(false);

  const handleChange = event => {
    let value = event.target.value;

    const error = required ? !value : false;
    const errorMessage = error ? 'Este campo é obrigatório' : null;

    setError(errorMessage);
    onError(errorMessage);

    return onChange(value);
  };

  useEffect(() => {
    onError(required ? !value : false);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="outlined">
        <InputLabel error={!!error || !!forceErrorText}>
          {label}
          {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
        </InputLabel>
        <Select
          native
          value={value}
          onChange={handleChange}
          label={label + ' *'}
          error={!!error || !!forceErrorText}
          {...props}
        >
          {options &&
            options.map((option, key) => {
              return (
                <option key={key} value={option}>
                  {option}
                </option>
              );
            })}
        </Select>
        {(error || forceErrorText) && (
          <FormHelperText error>{error || forceErrorText}</FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  );
};

Multiselect.propTypes = {
  onError: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  forceErrorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
};

Multiselect.defaultProps = {
  onError: () => {},
  options: [],
};

export default Multiselect;
