import { takeLatest, all, call } from 'redux-saga/effects';

import { showErrorAlert } from '@commons/utils/alert';
import api from '@commons/services/api';

export function* mudarStatusLote({ payload, resolve, reject }) {
  try {
    const { id, status_id } = payload;

    const response = yield call(api.post, `/lotes/${id}/mudar-status`, {
      status: status_id,
    });

    resolve(response.data);
  } catch (err) {
    showErrorAlert({
      message:
        'Erro ao mudar o status do lote. Por favor, recarregue a página.',
    });

    reject(err);
  }
}

export function* mudarMetodoPagamento({ payload, resolve, reject }) {
  try {
    const { id, condicao } = payload;

    const response = yield call(api.post, `/lotes/${id}/condicao`, {
      condicao,
    });

    resolve(response.data);
  } catch (err) {
    showErrorAlert({
      message:
        'Erro ao mudar a condição de pagamento do lote. Por favor, recarregue a página.',
    });

    reject(err);
  }
}

export default all([
  takeLatest('@admin/SET_LOTE_STATUS', mudarStatusLote),
  takeLatest('@admin/SET_METODO_PAGAMENTO', mudarMetodoPagamento),
]);
