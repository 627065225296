import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import OriginalTooltip from '@material-ui/core/Tooltip';

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(OriginalTooltip);

export const HtmlTooltip = ({ children, tooltipContent }) => {
  return <Tooltip title={tooltipContent}>{children}</Tooltip>;
};

HtmlTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  tooltipContent: PropTypes.any.isRequired,
};

export default HtmlTooltip;
