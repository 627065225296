export function getLeiloes(resolve, reject) {
  return {
    type: '@leiloes/GET_LEILOES',
    resolve,
    reject,
  };
}

export function getLotes(payload, resolve, reject) {
  return {
    type: '@leiloes/GET_LOTES',
    payload,
    resolve,
    reject,
  };
}
