import { showWarningAlert, showErrorAlert } from './alert';

const handleError = error => {
  const errorMessage = error.response?.data?.error;

  if (!!errorMessage) {
    return showWarningAlert({ message: errorMessage });
  }

  return showErrorAlert({});
};

export default handleError;
