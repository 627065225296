import { takeLatest, all, call } from 'redux-saga/effects';

import { map } from 'lodash';
import moment from 'moment';

import { showErrorAlert } from '@commons/utils/alert';
import { statusColors } from '@commons/utils/constants';
import api from '@commons/services/api';

export function* getLeiloes({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/leiloes');

    const today = moment().format('YYYY-MM-DD HH:mm:ss');

    const formattedData = map(response.data, item => {
      const color = statusColors[item.status_id];

      let eventStatus;

      if (!item.data_segundo_leilao) {
        eventStatus = 'Leilão Único';
      } else if (item.data_primeiro_leilao >= today) {
        eventStatus = '1º Leilão';
      } else {
        eventStatus = '2º Leilão';
      }

      const data =
        eventStatus === 'Leilão Único' || eventStatus === '1º Leilão'
          ? moment(item.data_primeiro_leilao)
          : moment(item.data_segundo_leilao);

      const hora = data.format('HH:mm:ss');
      const dia = data.format('DD/MM/YYYY');

      return {
        comitente: item.comitente,
        eventStatus,
        nome: item.nome,
        status: item.status?.nome,
        status_id: item.status_id,
        color,
        hora,
        dia,
        id: item.id,
      };
    });

    resolve(formattedData);
  } catch (err) {
    showErrorAlert({
      message: 'Erro ao buscar os leilões. Por favor, recarregue a página.',
    });

    console.error(err);

    reject(err);
  }
}

export function* getLotes({ payload, resolve, reject }) {
  try {
    const { id, page } = payload;

    const response = yield call(api.get, `/leiloes/${id}/lotes?page=${page}`);

    resolve(response.data);
  } catch (err) {
    showErrorAlert({
      message: 'Erro ao buscar os lotes. Por favor, recarregue a página.',
    });

    reject(err);
  }
}

export default all([
  takeLatest('@leiloes/GET_LEILOES', getLeiloes),
  takeLatest('@leiloes/GET_LOTES', getLotes),
]);
