import styled from 'styled-components';

import OriginalModal from '@material-ui/core/Modal';
import OriginalCloseIcon from '@material-ui/icons/Close';

import OriginalButton from '@commons/components/Button';

export const Modal = styled(OriginalModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`;

export const CloseButton = styled(OriginalButton)`
  position: absolute;
  align-self: flex-end;
  margin-bottom: 20px;
  padding: 0;
`;

export const Container = styled.div`
  border-radius: 4px;
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  background-color: ${props => props.theme.colors.grayLight};
  height: 300px;
  max-height: 80vh;
  overflow-y: auto;
  align-items: center;

  ${props => props.theme.breakPoints.hd} {
    width: 70%;
    margin-bottom: 20px;
    height: 350px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  > div {
    width: 100%;
  }
`;

export const CloseIcon = styled(OriginalCloseIcon)`
  path {
    color: ${props => props.theme.colors.white};
  }
`;
