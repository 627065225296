import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import api from '@commons/services/api';
import createStore from './createStore';
import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  const { auth } = store.getState();

  if (auth.access_token) {
    api.defaults.headers['Authorization'] = `Bearer ${auth.access_token}`;
  } else {
    delete api.defaults.headers['Authorization'];
  }
});

export { store, persistor };
