export function mudarStatusLote(payload, resolve, reject) {
  return {
    type: '@admin/SET_LOTE_STATUS',
    payload,
    resolve,
    reject,
  };
}

export function mudarMetodoPagamento(payload, resolve, reject) {
  return {
    type: '@admin/SET_METODO_PAGAMENTO',
    payload,
    resolve,
    reject,
  };
}
