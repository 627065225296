import styled from 'styled-components';

import OriginalModal from '@material-ui/core/Modal';
import OriginalCloseIcon from '@material-ui/icons/Close';

import OriginalButton from '@commons/components/Button';

export const Modal = styled(OriginalModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`;

export const CloseButton = styled(OriginalButton)`
  position: absolute;
  align-self: flex-end;
  margin-bottom: 20px;
  padding: 0;
`;

export const EnviarLanceButton = styled(OriginalButton)`
  height: 55px;
  background-color: ${props => props.theme.colors.darkerRed};
  font-size: 1.2rem;
  width: 200px;
  margin-top: 40px;
  align-self: flex-end;
`;

export const DetalhesContainer = styled.div`
  border-radius: 4px;
  min-width: 600px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: ${props => props.theme.colors.white};
  max-height: 80vh;
  overflow-y: auto;

  ${props => props.theme.breakPoints.hd} {
    width: 70%;
    margin-bottom: 20px;
  }
`;

export const CloseIcon = styled(OriginalCloseIcon)`
  path {
    color: ${props => props.theme.colors.darker};
  }
`;

export const LanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  h3 {
    font-size: 30px;
    color: ${props => props.theme.colors.darker};
  }

  .lance-automatizado-description {
    margin: 5px 0 40px;
  }

  .MuiFormControl-root {
    width: 100%;
  }
`;
