import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;

  > div {
    margin: 20px;
  }
`;
