import { takeLatest, all, call } from 'redux-saga/effects';

import api from '@commons/services/api';
import { showErrorAlert } from '@commons/utils/alert';

export function* getLoteData({ payload, resolve, reject }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `/lotes/${id}`);

    resolve(response.data);
  } catch (err) {
    showErrorAlert({
      message: 'Erro ao buscar os lotes. Por favor, recarregue a página.',
    });

    reject(err);
  }
}

export function* enviarLance({ payload, resolve, reject }) {
  try {
    const { id, valor } = payload;

    const body = {
      lote_id: id,
      valor,
    };

    const response = yield call(api.post, `lotes/lances/registra`, body);

    resolve(response.data);
  } catch (err) {
    reject(err);
  }
}

export function* getLances({ payload, resolve, reject }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `lotes/${id}/lances`);

    resolve(response.data);
  } catch (err) {
    reject(err);
  }
}

export default all([
  takeLatest('@lotes/GET_LOTE_DATA', getLoteData),
  takeLatest('@lotes/ENVIAR_LANCE', enviarLance),
  takeLatest('@lotes/GET_LANCES', getLances),
]);
