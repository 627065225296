import React from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TableHeader = ({ columns }) => {
  return (
    <TableHead>
      <TableRow>
        {map(columns, (column, index) => {
          return (
            <TableCell
              style={column.style}
              key={index}
              align={column.align || 'left'}
            >
              {column.title}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
};

TableHeader.defaultProps = {
  columns: [],
};

export default TableHeader;
