import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Sidebar from './Sidebar';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const applyDashboardNavigator = Component => {
  return props => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Component {...props} />
        </main>
      </div>
    );
  };
};

export default applyDashboardNavigator;
