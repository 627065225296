import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';

import { mudarMetodoPagamento } from '@commons/store/modules/admin/actions';
import { setLoading } from '@commons/store/modules/dashboard/actions';

import { Container, Multiselect, Alert } from './styles';
import { metodosPagamento } from './utils';

export const BatidasMartelo = ({ lote }) => {
  const dispatch = useDispatch();

  const [alertOpen, setAlertOpen] = useState(false);

  const doMudarMetodoPagamento = condicao => {
    const mudarMetodoPagamentoPromise = () => {
      return new Promise((resolve, reject) => {
        dispatch(
          mudarMetodoPagamento({ id: lote.id, condicao }, resolve, reject)
        );
      });
    };

    dispatch(setLoading(true));

    mudarMetodoPagamentoPromise()
      .then(() => setAlertOpen(true))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSelectChange = value => {
    doMudarMetodoPagamento(value);
  };

  return (
    <Container>
      <Multiselect
        value={lote.condicao}
        label="Condição de pagamento"
        options={metodosPagamento}
        onChange={handleSelectChange}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={2000}
        key={'snack-success'}
      >
        <Alert variant="filled" onClose={() => setAlertOpen(false)}>
          Condição de pagamento alterada com sucesso!
        </Alert>
      </Snackbar>
    </Container>
  );
};

BatidasMartelo.propTypes = {
  lances: PropTypes.array,
};

export default BatidasMartelo;
