export function getLoteData(payload, resolve, reject) {
  return {
    type: '@lotes/GET_LOTE_DATA',
    payload,
    resolve,
    reject,
  };
}

export function enviarLance(payload, resolve, reject) {
  return {
    type: '@lotes/ENVIAR_LANCE',
    payload,
    resolve,
    reject,
  };
}

export function getLances(payload, resolve, reject) {
  return {
    type: '@lotes/GET_LANCES',
    payload,
    resolve,
    reject,
  };
}
