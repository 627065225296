import styled from 'styled-components';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const Footer = styled.div`
  margin-top: auto;
`;

export const ExitIcon = styled(ExitToAppIcon)`
  transform: rotate(180deg);
`;
