import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const ToolbarIcon = ({ classes, theme, handleDrawerClose }) => {
  return (
    <div className={classes.toolbar}>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </div>
  );
};

ToolbarIcon.propTypes = {
  classes: PropTypes.any.isRequired,
  theme: PropTypes.any.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default ToolbarIcon;
