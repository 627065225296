export const columns = [
  {
    title: 'Número',
    dataKey: 'numero',
  },
  {
    title: 'Hash',
    dataKey: 'hash',
  },
  {
    title: 'Emitida em',
    dataKey: 'created_at',
  },
  {
    title: 'Evento',
    dataKey: 'eventName',
  },
  {
    title: 'Lote',
    dataKey: 'loteName',
  },
  {
    title: 'Fatura',
    dataKey: 'fatura',
    contentIsReactComponent: true,
    align: 'right',
  },
  {
    title: 'Arquivos',
    dataKey: 'files',
    contentIsReactComponent: true,
    align: 'right',
  },
];
