import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const Flag = styled.span`
  padding: 5px 15px;
  background-color: ${props => props.color};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 5px;
  font-size: 1rem;
  margin: 10px 10px 40px 0;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
