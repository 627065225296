import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Faturas from '@commons/pages/Faturas';
import GerenciarLote from '@commons/pages/GerenciarLote';
import Leiloes from '@commons/pages/Leiloes';
import Login from '@commons/pages/Login';
import Lote from '@commons/pages/Lote';
import Lotes from '@commons/pages/Lotes';
import api from '@commons/services/api';
import { logout } from '@commons/store/modules/auth/actions';

// Pages

const Routes = () => {
  const dispatch = useDispatch();
  const { access_token } = useSelector(state => state.auth);

  //Interceptor para deslogar usuário ao receber HTTP 401
  api.interceptors.response.use(
    response => {
      return Promise.resolve(response);
    },
    error => {
      const store = localStorage.getItem('persist:tradeleiloes');

      let { auth } = JSON.parse(store);

      const { access_token: token } = JSON.parse(auth);

      if (error.response?.status === 401 && token != null) {
        return dispatch(logout());
      }

      return Promise.reject(error);
    }
  );

  const Auth = (
    <Switch>
      <Route path="/" exact component={Login} />
      <Redirect to={{ pathname: '/' }} />
    </Switch>
  );

  const App = (
    <Switch>
      <Route path="/leiloes" exact component={Leiloes} />
      <Route path="/leiloes/:id" exact component={Lotes} />
      <Route path="/lotes/:id" exact component={Lote} />
      <Route path="/lotes/:id/admin" exact component={GerenciarLote} />
      <Route path="/faturas" exact component={Faturas} />
      <Redirect to={{ pathname: '/leiloes' }} />
    </Switch>
  );

  //Carrega as rotas APP caso tenha token e Auth se não tiver
  return access_token === null ? Auth : App;
};

export default Routes;
