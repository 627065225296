export const columns = [
  {
    title: 'Usuário',
    dataKey: 'user',
  },
  {
    title: 'Valor',
    dataKey: 'valor',
  },
  {
    title: 'Horário',
    dataKey: 'time',
    align: 'right',
  },
];
