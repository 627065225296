import styled from 'styled-components';

import OriginalPagination from '@material-ui/lab/Pagination';

export const Pagination = styled(OriginalPagination)`
  margin-top: 50px;

  button {
    font-size: 1rem;
    font-weight: bold;
  }

  .Mui-selected {
    background-color: ${props => props.theme.colors.primary} !important;
    color: white !important;
  }

  ul {
    li:last-of-type button {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);

      svg {
        height: 1.3rem;
        width: 1.3rem;
      }
    }
  }
`;

export const PaginationDescription = styled.span`
  font-size: 1rem;
  margin: 10px 0;
`;
